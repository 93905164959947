import { Box, Link } from '@mui/material';
import { styled } from '@mui/system';

export const StyledHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#0B2652',
  padding: theme.spacing(3),
  paddingLeft: '152px',
  paddingRight: '152px',
  color: theme.palette.text.primary,
}));

export const StyledLogoBox = styled(Box)({
  display: 'inline-block',
  height: '22.4px',
  borderLeft: '2px solid white',
  marginLeft: '8px',
  marginRight: '8px',
  verticalAlign: 'middle',
});

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: '#FFFFFF',
  fontWeight: 500,
});
