import { TableCell } from '@mui/material';
import { styled } from '@mui/system';

// using important to allow for TableCell Borders, the MuiTableRow theme was preventing it
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: '1px solid #CECECE !important', 
  color: theme.palette.text.primary, 
}));

export default StyledTableCell;
