import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";


const CampaignDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  

  return (
    <Grid container direction="column" spacing={2} sx={{ padding: '20px' }}>

  
      <Grid item>
        <Grid item>
          <Box
            onClick={() => navigate('/admin/campaigns')}
            sx={{ cursor: 'pointer' }}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <IconButton color="primary" sx={{ padding: '0', marginRight: '10px' }}>
                  <ArrowBack />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textPrimary">
                  ALL CAMPAIGNS
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Typography variant="h4" color="textPrimary" sx={{ marginTop: '10px' }}>
          </Typography>
        </Grid>

      </Grid>

      <Grid item container spacing={3} sx={{ }}>

        {/* Left Column (Tools) */}
        <Grid item xs={12} md={7}>
          <Typography variant="h6">Tools</Typography>
          {id}  ID OF THE BUYER / CAMPAIGN

        </Grid>

        {/* Right Column (About) */}
        <Grid item xs={12} md={5}>
          <Typography variant="h6">About</Typography>
        </Grid>
        
      </Grid>
    </Grid>
  );
};

export default CampaignDetailsPage