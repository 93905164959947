import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography, Table, TableBody, TableHead, TableRow, TablePagination, Paper, TableContainer } from '@mui/material';
import StyledTableCell from '../common/StyledTableCell';

interface CampaignTableProps {
  searchQuery?: string | null;
  buyerTransactionsData?: BuyerTransactionsData;
}

interface BuyerTransactionsData {
  buyersMostRecentTransaction?: BuyersMostRecentTransaction;
}

interface BuyersMostRecentTransaction {
  buyerRecentTransactions?: BuyerTransaction[];
}

interface MostRecentTransaction {
  dateModified: Date;
  id: number;
  status: string;
}

interface BuyerTransaction {
  id: number;
  mostRecentTransaction: MostRecentTransaction;
  name: string;
}

const CampaignsTable = (props: CampaignTableProps) => {
  const { searchQuery = '', buyerTransactionsData } = props;
  const buyerTransactions = buyerTransactionsData?.buyersMostRecentTransaction?.buyerRecentTransactions || [];

  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const orderedBuyerTransactions = [...buyerTransactions]
  .sort((a, b) => {
    const dateA = a.mostRecentTransaction?.dateModified
      ? new Date(a.mostRecentTransaction.dateModified).getTime()
      : 0;
    const dateB = b.mostRecentTransaction?.dateModified
      ? new Date(b.mostRecentTransaction.dateModified).getTime()
      : 0;
    return dateB - dateA;
  });

  const filteredBuyers = orderedBuyerTransactions.filter(buyer =>
    buyer.name?.toLowerCase().includes((searchQuery ?? '').toLowerCase())
  );

  return (

    <TableContainer component={Paper}  >
      <Table>
        <TableHead>
          <TableRow
            sx={{
              cursor: 'default',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}>
            <StyledTableCell>
              <Typography sx={{ fontSize: '19px', fontWeight: '500' }}>
                Name
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography sx={{ fontSize: '19px', fontWeight: '500' }}>
                Status
              </Typography>
            </StyledTableCell>
            <StyledTableCell >
              <Typography sx={{ fontSize: '19px', fontWeight: '500' }}>
                Last activity
              </Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredBuyers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: BuyerTransaction, index: number) => {
            const transaction = row.mostRecentTransaction;
            const buyerName = row.name
            const status = transaction ? transaction.status : 'No Status Available';
            const dateModified = transaction ? new Intl.DateTimeFormat('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              timeZoneName: 'short'
            }).format(new Date(transaction.dateModified))
              : 'No Date Available';

            return (
              <TableRow
                key={index}
                sx={{ cursor: 'pointer' }}
                hover
                onClick={() => navigate(`/admin/campaign/${row.id}`)}
              >
                <StyledTableCell>
                  <Typography sx={{ fontSize: '16px' }}>
                    {buyerName}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography sx={{ fontSize: '16px' }}>
                    {status}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography sx={{ fontSize: '16px' }}>
                    {dateModified}
                  </Typography>
                </StyledTableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        page={page}
        rowsPerPageOptions={[10, 20, 100]}
        count={buyerTransactions.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}

export default CampaignsTable