import shortLogo from '../../staclabs_shortlogo.svg';
import { Box, Grid, Typography } from '@mui/material';
import { StyledHeader, StyledLogoBox, StyledLink } from '../common/StyledAdminHeader';


const AdminHeader = () => {
  
  return (
    <StyledHeader>
      <Grid container spacing={2} textAlign="left" alignItems="center">
        <Grid item xs>
          <StyledLink href='/admin' variant="h6" underline="none">
            <Box component="img" src={shortLogo} alt="logo" sx={{ height: 20, mr: 1 }} />
            <Typography variant="h5" component="span">
              DemLaunch
            </Typography>
            <StyledLogoBox />
            <Typography
              variant="body2"
              component="span"
              sx={{
                fontWeight: 500,
                letterSpacing: 1,
                fontSize: '12.8px',
                verticalAlign: 'middle',
              }}
            >
              ADMIN
            </Typography>
          </StyledLink>
        </Grid>

        <Grid item xs={6}>
          <Grid container spacing={7} sx={{ ml: '20px', fontSize: '18px' }}>
            <Grid item>
              <StyledLink href="">All campaigns</StyledLink>
            </Grid>
            <Grid item>
              <StyledLink href="">Knowledge center</StyledLink>
            </Grid>
            <Grid item>
              <StyledLink href="">Settings</StyledLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledHeader>
  );
};

export default AdminHeader;